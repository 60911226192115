<template lang="pug">
  include /mixins
  div(
    v-bem=''
    :class='inputClass'
  )
    component(
      :is='labelComponent',
      :id='id',
      :value='value',
      :labelText='labelText',
      :input-label='inputLabel',
      :is-required='required'
      :hint-right="hintRight"
      v-if="inputLabel"
    )
    +b.d-control-input__wrapper
      component.d-control-input__element(
        v-if="mask"
        :class="{'d-control-input__element--icon_prepend': prependIcon, 'd-control-input__element--icon_append': appendIcon}"
        :is="element",
        :value.prop='value',
        ref='input'
        v-mask="{mask: mask, placeholder: '_', clearMaskOnLostFocus: clearMaskOnLostFocus, showMaskOnHover: false}"
        v-on="listeners"
        :id='id',
        v-bind='$attrs'
        v-model='model',
      )
      component(
        v-if="prependIcon"
        :is="prependComponent"
        :prepend-icon="prependIcon"
        :prepend-icon-func="prependIconFunc"
      )
      component.d-control-input__element(
        v-if="!mask"
        :class="{'d-control-input__element--icon_prepend': prependIcon, 'd-control-input__element--icon_append': appendIcon}"
        :is="element",
        :value.prop='value',
        ref='input'
        v-on="listeners"
        :id='id',
        v-bind='$attrs'
        :type='type'
        v-model='model',
      )
        template(
          v-for="(name, key) in eSlots"
          v-slot:[name]="binded"
        )
          slot(
            v-bind="binded"
            :name="key"
          )
      component(
        v-if="appendIcon"
        :is="appendComponent"
        :append-icon="appendIcon"
        :append-icon-func="appendIconFunc"
      )
        template(slot="appendIcon")
          slot(name="appendIcon")
      +b.d-control-input__icon.--append.is-interactive(
        v-if='type == "password" && !eyesIsHidden'
        @click.prevent='changeType()',
        :class='!show ? "icon-eyes1" : "icon-eyes2"'
      )
</template>

<script>
const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

export default {
  name: 'element-input',
  props: [
    'prependComponent',
    'prependIcon',
    'prependIconFunc',
    'appendComponent',
    'appendIcon',
    'appendIconFunc',
    'inputClass',
    'labelComponent',
    'id',
    'value',
    'labelText',
    'inputLabel',
    'required',
    'providerContext',
    'type',
    'element',
    'mask',
    'clearMaskOnLostFocus',
    'eyesIsHidden',
    'hintRight',
  ],
  block: 'd-control-input',
  data() {
    return {
      input: '',
      show: false,
    }
  },
  watch: {
    value: {
      handler() {
        this.input = this.value
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots))
    },
    listeners() {
      return {
        ...this.$listeners,
        input: $event => this.$emit('input', this.getEvent($event)),
      }
    },
    model: {
      get() {
        return this.input
      },
      set(e) {
        this.input = e
      },
    },
  },
  methods: {
    getEvent($event) {
      if ($event && $event.timeStamp) {
        return $event.target[this.getKey()]
      }
      return $event
    },
    getKey() {
      return 'checkbox' === this.type ? 'checked' : 'value'
    },
    changeType() {
      this.show = !this.show
      if (this.show) {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = this.type
      }
    },
  },
}
</script>
