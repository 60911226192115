<template lang="pug">
  include /mixins
  +b.LABEL.d-control-input__label--static(:for='id')
    +b.g-row.--space_md.--align_center.--appearance_spaced(
      :class="{ 'g-row--justify_between': Boolean(hintRight) }"
    )
      +b.g-cell
        span {{ inputLabel }}
        span(
          v-if='isRequired'
          v-bem.is-required=''
        ) *
      +b.g-cell(v-if="hintRight")
        +b.i-circle.--variant_3.--interactive(
          v-tooltip.auto="{ content: hintRight, trigger: 'click' }"
        )
          +b.I.icon-circle-info
</template>

<script>
export default {
  name: 'StaticLabel',
  block: 'd-control-input',
  props: {
    id: {
      type: String,
      required: false,
    },
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    hintRight: {
      type: String,
    },
  },
}
</script>
